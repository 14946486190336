import {createSlice} from "@reduxjs/toolkit";

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: {},
        data: {},
        deliveryCity: undefined,
        deliveryMethod: {},
        deliveryPrice: 0
    },
    reducers: {
        updateCart: (state, action) => {
            // uuid is used to identify the caller, can be removed later
            // console.log('uuid',action.payload.uuid)
            state.cart = action.payload.data
        },
        updateCartData: (state, action) => {
            state.data = {
                ...state.data,
                ...action.payload
            }
        },
        clearCartData: (state, action) => {
            state.data = {}
        },
        updateDeliveryCity: (state, action) => {
            state.deliveryCity = action.payload
        },
        updateDeliveryMethod: (state, action) => {
            state.deliveryMethod = action.payload
        },
        updateDeliveryPrice: (state, action) => {
            state.deliveryPrice = action.payload
        }
    }
});

export const {
    updateCart,
    updateCartData,
    updateDeliveryCity,
    updateDeliveryMethod,
    updateDeliveryPrice,
    clearCartData
} = cartSlice.actions

export default cartSlice.reducer