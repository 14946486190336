"use client";
import Link from "next/link";
import { usePathname, useRouter } from 'next/navigation';
import styles from "../../styles/components/header.module.scss";
import { get } from "http";
import { useState } from "react";

const HeaderMobileNav = ({ data, firstLevel = true, setMenuOpen = null }) => {
    const pathname = usePathname();
    const router = useRouter();
    let shouldNavigate = false;

    const getChildren = (data, parentID) => {

        if (!data) {
            return []
        }

        return data.filter(x => x.menu_item_parent == parentID)
    }

    const handleLinkClick = (item) => {
        if (window.innerWidth < 1220 && getChildren(data, item.ID).length > 0 && !shouldNavigate) {
            shouldNavigate = true;
        } else {
            shouldNavigate = false;
            setMenuOpen(false);
            router.push(item.description ? item.description : '/');
        }
    };


    const renderMenu = (data) => {

        let result = []

        for (let item of data) {
            const children = getChildren(data, item.ID)
            if (item.menu_item_parent > 0 && firstLevel) {
                continue
            }

            result.push(
                <li key={"header-" + item.ID}>
                    <Link
                        href={item.description ? item.description : '/'}
                        className={pathname === `${item.description}` ? styles.activeMenuItem : ""}
                        onClick={() => handleLinkClick(item)}>
                        {item.title}
                    </Link>


                    {
                        children && children.length > 0 &&
                        <div className={styles.submenu}>
                            <HeaderMobileNav data={children} firstLevel={false} setMenuOpen={setMenuOpen} />
                        </div>
                    }
                </li>
            )
        }

        return result
    }



    return (
        <div className={styles.navItems}>
            <ul>
                {renderMenu(data)}
            </ul>
        </div>
    )
}

export default HeaderMobileNav;
