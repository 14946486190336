"use client";

import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import store from "@/app/store";

const ServerIntlProvider = ({ messages, locale, children }) => {
  return (
    <IntlProvider messages={messages} locale={locale}>
      <Provider store={store}>{children}</Provider>
    </IntlProvider>
  );
};

export default ServerIntlProvider;
