import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    whoCategory: null,
    eventCategory: null,
    location: null,
    price: null,
    category: null
}
export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        updateWhoCategory: (state, action) => {
            state.whoCategory = action.payload
        },
        updateEventCategory: (state, action) => {
            state.eventCategory = action.payload
        },
        updateLocation: (state, action) => {
            state.location = action.payload
        },
        updatePrice: (state, action) => {
            state.price = action.payload
        },
        updateCategory: (state, action) => {
            state.category = action.payload
        },
        resetFilters: (state) => {
            state = initialState
        }
    }
});

export const {updateWhoCategory, updateEventCategory, updateLocation, updatePrice, resetFilters} = filterSlice.actions


export default filterSlice.reducer