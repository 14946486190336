export const addToCart = async (data) => {
    const result = await fetch('/api/cart', {
        method: 'POST',
        body: JSON.stringify(data)
    })
    return await result.json()
}

export const getCart = async () => {
    const result = await fetch('/api/cart/')
    return await result.json()
}

export const getCartCount = async () => {
    const result = await fetch('/api/cart/count')
    return await result.json()
}

export const deleteCartItem = async (id) => {
    const result = await fetch(`/api/cart?id=${id}`, {
        method: 'DELETE'
    })
    return await result.json()
}
export const emptyCart = async () => {
    const result = await fetch(`/api/cart`, {
        method: 'DELETE'
    })
    return await result.json()
}