'use client'
import Image from "next/image";
import {useEffect, useState} from "react";
import {getCart, getCartCount} from "@/app/services/CartService";
import styles from '@/app/styles/components/layout/cart-icon.module.scss'
import Link from "next/link";
import {useSelector} from "react-redux";

const CartIcon = () => {

    const reduxCart = useSelector((state) => state.cart?.cart);
    const [cartCount, setCartCount] = useState(0)

    useEffect(() => {

        getCartCount().then(response => {
            setCartCount(response)
        })

    }, [])

    useEffect(() => {
        setCartCount(reduxCart ? Object.keys(reduxCart).length : 0)
    }, [reduxCart])

    return <div className={styles.cartIconContainer}>
        <Link href={'/korpa'}>
            <Image
                src={'/cart.svg'}
                alt="Korpa"
                width={30}
                height={30}
            />
            {
                cartCount > 0 &&
                <div className={styles.cartSize}>{cartCount}</div>
            }
        </Link>
    </div>
}

export default CartIcon