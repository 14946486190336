'use client'

import Link from "next/link";
import styles from "@/app/styles/components/header.module.scss";
import {signOut, useSession} from "next-auth/react";
import {FormattedMessage} from "react-intl";

const AuthCTA = ({data}) => {
    const {data: session} = useSession()
    const {header_login_link, header_login_text, header_btn_link, header_btn_text} = data
    return (
        <div className={styles.btnsContainer}>{session && session.user ? (
            <div className={styles.userEmail}>
                <p className={styles.userName}>{session.user.user_email}</p>
                <FormattedMessage id={'auth.signOut'}>
                    {(message) =>
                        <button onClick={() => signOut()} className={`${styles.btnSignUp} btnGlobal`}>{message}</button>
                    }
                </FormattedMessage></div>
        ) : (
            <div className={styles.btnLoginSignUp}>
                <Link
                    className={styles.login}
                    href={header_login_link}
                >
                    {header_login_text}
                </Link>
                <Link
                    className={`${styles.btnSignUp} btnGlobal`}
                    href={header_btn_link}
                >
                    {header_btn_text}
                </Link>
            </div>
        )
        }</div>

    )
}

export default AuthCTA;