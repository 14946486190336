"use client"
import { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import CartIcon from "@/app/components/layout/CartIcon";
import AuthCTA from "@/app/components/layout/AuthCTA";
import HeaderMobileNav from "@/app/components/layout/header-mobile-nav";
import styles from "@/app/styles/components/header-mobile.module.scss";
import {getCDNImage} from "@/app/utils/ImageUtil";

const HeaderMobile = ({ data, dataOptions }) => {

    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className={styles.headerMobile}>
            <div className="container">
                <div className={styles.contentHeader}>
                    <div className={styles.logo}>
                        <Image
                            loading={"eager"}
                            className={styles.logoImage}
                            src={getCDNImage(dataOptions.acf.header_logo)}
                            alt="Pokloni za najmilije logo"
                            width={64}
                            height={61}
                        />
                        <Link href="/" className={styles.logoLink}></Link>
                    </div>
                    <div className={`${styles.menuMobile} ${menuOpen ? styles.show : styles.hide} `}>
                        <div className={styles.menuItemsContainer}>
                            <HeaderMobileNav data={data} setMenuOpen={setMenuOpen} />
                        </div>
                        <AuthCTA data={dataOptions.acf} />
                    </div>

                    <div className={styles.heartCartDiv}>
                        <div className={styles.headerCartImageDiv}>
                            <CartIcon />
                        </div>
                    </div>
                    <div className={`${styles.hamburgerMenu} ${menuOpen ? styles.active : ``} `} onClick={handleMenuToggle}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderMobile;



